import React, { Fragment } from "react";
import { Link } from "gatsby";

const WorkMenuTemplate = ({ work, archived }) => {
  return (
    <aside className={`work-menu`}>
      <div className={"work-menu__inner"}>
        {work && work.length > 0 && (
          <Fragment>
            <h2 className="work-menu__title">Recent</h2>
            <nav className={`work-menu__nav`}>
              <ul className={`work-menu__list work-menu__list--featured`}>
                {work.map(({ id, to, title }) => (
                  <li key={id} className={"work-menu__item"}>
                    <Link
                      to={to}
                      className={"work-menu__link"}
                      activeClassName={`work-menu__link--active`}
                    >
                      {title}
                    </Link>
                  </li>
                ))}
              </ul>
            </nav>
          </Fragment>
        )}

        {archived && archived.length > 0 && (
          <Fragment>
            <h2 className="work-menu__title">Archive</h2>
            <nav className={`work-menu__nav`}>
              <ul className={`work-menu__list`}>
                {archived.map(({ id, to, title }) => (
                  <li key={id} className={"work-menu__item"}>
                    <Link
                      to={to}
                      className={"work-menu__link"}
                      activeClassName={`work-menu__link--active`}
                    >
                      {title}
                    </Link>
                  </li>
                ))}
              </ul>
            </nav>
          </Fragment>
        )}
      </div>
    </aside>
  );
};

export default WorkMenuTemplate;
