import React from "react";
import { StaticQuery, graphql } from "gatsby";
import WorkMenuTemplate from "./work-menu-template";

function WorkMenu({
  pageId
}) {
  return (
    <StaticQuery
      query={graphql`
        query workItemsQuery {
          allMarkdownRemark(
            sort: {
              order: DESC
              fields: [
                frontmatter___date
                frontmatter___title
              ]
            }
            filter: { frontmatter: { template: { eq: "work-item" } } }
          ) {
            edges {
              node {
                id
                frontmatter {
                  slug
                  title
                  date
                  archived
                }
              }
            }
          }
        }
      `}

      render={data => {
        const workSlug = '/work';
        const { archived, work } = data.allMarkdownRemark.edges
          .filter(edge => !!edge.node.frontmatter.date)
          .reduce((result, edge) => {
            const id = edge.node.id;
            const frontmatter = edge.node.frontmatter;
            const { slug, archived, title, date } = frontmatter;
            const item = {
              id,
              isActive: pageId === id,
              to: `${workSlug}${slug}`,
              title,
              date
            };
            if (archived) {
              result.archived.push(item);
            } else {
              result.work.push(item);
            }
            return result;
          }, { archived: [], work: [] });

        return <WorkMenuTemplate work={work} archived={archived} />;
      }}
    />
  );
}

export default WorkMenu;