import React from "react";
import Img from "gatsby-image";
import classNames from "classnames";

const WorkGallery = ({
  images = [],
  type = "",
  position = "",
  handleImageClick,
}) => {
  if (images.length === 0) {
    return null;
  }

  function renderImage(imageData) {
    const { image, title, alt = "" } = imageData;

    if (!image) {
      return null;
    }

    if (image.childImageSharp?.fluid) {
      return (
        <Img
          fluid={image.childImageSharp.fluid}
          objectFit="inside"
          alt={alt}
          title={title}
          className="gallery-image"
        />
      );
    } else if (!!image.publicURL) {
      return (
        <img
          src={image.publicURL}
          alt={alt}
          title={title}
          className="gallery-image"
        />
      );
    } else if (!!image && typeof image === "string") {
      return (
        <img src={image} alt={alt} title={title} className="gallery-image" />
      );
    }
    return null;
  }

  function renderCaption({ title, description }) {
    if (!title && !description) {
      return null;
    }

    // title ends with a ,
    // const titleComma = title && title.trim().slice(-1) === ","
    // const descriptionComma = title && title.trim().slice(0, 1) === ","
    // const hasComma = titleComma || descriptionComma;

    return (
      <figcaption style={{ whiteSpace: "pre-line" }}>
        {title && <span className="figcaption--title">{title}</span>}
        {description && title ? ` ${description}` : description}
      </figcaption>
    )
  }

  return (
    <div
      className={classNames("work-gallery", {
        "work-gallery--grid": type === "grid",
        "work-gallery--column": type === "column",
        "work-gallery--start": position === "start",
        "work-gallery--end": position === "end",
      })}
    >
      {images.map((imageData, index) => {
        return (
          <figure
            className="gallery-figure"
            onClick={
              !!handleImageClick ? event => handleImageClick(event) : undefined
            }
            role="presentation"
            key={index}
          >
            <div className="gallery-image-wrapper">{renderImage(imageData)}</div>
            {renderCaption(imageData)}
          </figure>
        );
      })}
    </div>
  );
};

export default WorkGallery;
