import React, { useState } from "react";
import { graphql } from "gatsby";
import { HTMLContent } from "../components/content";
import Layout from "../components/layout";
import SEO from "../components/seo";
import WorkMenu from "../components/work-menu";
import WorkItemPageTemplate from "../components/work-item-page-template";
import Lightbox from "../components/lightbox";

function findImages(children) {
  let images = [];
  children.forEach(item => {
    const { type, tagName, children } = item;
    if (type === "element" && tagName === "img") {
      images.push(item);
      return;
    }
    if (Array.isArray(children) && children.length > 0) {
      const foundImages = findImages(children);
      if (foundImages.length > 0) {
        images = [...images, ...foundImages];
      }
    }
    return;
  });
  return images;
}

const WorkItemPage = ({ data, pageContext }) => {
  const [lightboxState, setLightboxState] = useState({
    isOpen: false,
    photoSrc: "",
  });
  const { id } = pageContext;
  const { markdownRemark } = data; // data.markdownRemark holds your post data
  const { frontmatter, html, htmlAst, excerpt } = markdownRemark;
  const Image = frontmatter.featuredImage?.childImageSharp?.fluid
    ? frontmatter.featuredImage.childImageSharp.fluid
    : "";

  let images = [];
  let galleryImages = frontmatter.gallery?.images || [];

  let markdownImages = findImages(htmlAst.children).filter(
    child => !!child.properties.src
  );

  if (Image) {
    images.push({
      src: Image.src,
      srcThumbnail: Image.srcSet
        ? Image.srcSet.split(",")[0].split(" ")[0]
        : Image.src,
      title: Image.title,
    });
  }

  galleryImages.forEach(({ image, title, description }) => {
    const src = image.childImageSharp
      ? image.childImageSharp.fluid.src
      : image.publicURL;
    const srcThumbnail = image.childImageSharp?.fluid?.srcSet
      ? image.childImageSharp.fluid.srcSet[0].split(" ")[0]
      : src;
    if (src) {
      images.push({
        src,
        srcThumbnail,
        title,
        description,
      });
    }
  });

  markdownImages.forEach(image => {
    images.push({
      src: image.properties.src,
      srcThumbnail: image.properties.srcSet
        ? image.properties.srcSet[0].split(" ")[0]
        : image.properties.src,
      title: image.properties.title,
    });
  });

  function handleMarkdownImageClick(event) {
    const target = event.target;
    console.log("markdown click", event.target);
    if (target.className === "gatsby-resp-image-image") {
      presentLightbox(target.src);
    }
  }

  function handleFeaturedImageClick(event) {
    const target = event.target;
    if (target.tagName === "IMG") {
      presentLightbox(target.src);
    }
  }

  function handleGalleryImageClick(event) {
    const target = event.target;
    if (target.tagName === "IMG") {
      presentLightbox(target.src);
    }
  }

  function presentLightbox(src) {
    if (src) {
      setLightboxState({ isOpen: true, photoSrc: new URL(src).pathname });
    }
  }

  function handleLightboxClose() {
    setLightboxState({ isOpen: false, photoSrc: "" });
  }

  return (
    <Layout className="work-page work-item-page work-item">
      <SEO
        title={frontmatter.title}
        description={
          frontmatter.description ? frontmatter.description : excerpt
        }
        image={Image.src}
        article={true}
      />
      {images.length > 0 && (
        <Lightbox
          images={images}
          photoSrc={lightboxState.photoSrc}
          isOpen={lightboxState.isOpen}
          onClose={() => handleLightboxClose()}
        />
      )}
      <WorkMenu pageId={id} />
      <WorkItemPageTemplate
        title={frontmatter.title}
        date={frontmatter.date}
        featuredImage={frontmatter.featuredImage}
        gallery={frontmatter.gallery}
        content={html}
        contentComponent={HTMLContent}
        handleFeaturedImageClick={event => handleFeaturedImageClick(event)}
        handleGalleryImageClick={event => handleGalleryImageClick(event)}
        handleMarkdownImageClick={event => handleMarkdownImageClick(event)}
      />
    </Layout>
  );
};

export default WorkItemPage;

export const pageQuery = graphql`
  query WorkItemQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      htmlAst
      excerpt(pruneLength: 148)
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
        description
        gallery {
          style
          position
          images {
            image {
              publicURL
              childImageSharp {
                fluid(
                  maxWidth: 1980
                  quality: 80
                  srcSetBreakpoints: [350, 700, 1050, 1400]
                ) {
                  ...GatsbyImageSharpFluid
                  ...GatsbyImageSharpFluidLimitPresentationSize
                }
                sizes {
                  src
                }
              }
            }
            title
            alt
            description
          }
        }
        featuredImage {
          childImageSharp {
            fluid(
              maxWidth: 1980
              quality: 80
              srcSetBreakpoints: [350, 700, 1050, 1400]
            ) {
              ...GatsbyImageSharpFluid
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
            sizes {
              src
            }
          }
        }
      }
    }
  }
`;
