import React from "react";
import Img from "gatsby-image";

const WorkFeaturedImage = ({
  imageInfo,
  title = "",
  description = "",
  handleImageClick,
}) => {
  const imageStyle = {
    // position: 'absolute',
    top: "0px",
    left: "0px",
    width: "100%",
    height: "100%",
    maxWidth: "100%",
    maxHeight: "100%",
    objectFit: "cover",
    objectPosition: "center center",
    opacity: 1,
    transition: "opacity 500ms ease 0s",
  };
  const { childImageSharp, url } = imageInfo;

  if (!childImageSharp && !url) {
    return null;
  }

  return (
    <div
      className="featured-image__container"
      role="presentation"
      onClick={
        !!handleImageClick ? event => handleImageClick(event) : undefined
      }
    >
      {!!childImageSharp && (
        <Img
          fluid={childImageSharp.fluid}
          objectFit="inside"
          style={{ maxWidth: "100%", maxHeight: "100%" }}
          objectPosition="50% 50%"
          alt={title}
          className="featured-image"
        />
      )}
      {!!url && (
        <img
          style={imageStyle}
          src={url}
          alt={title}
          className="featured-image"
        />
      )}
    </div>
  );

  // if (!!childImageSharp) {
  //   return <Img
  //     style={imageStyle}
  //     fluid={childImageSharp.fluid}
  //     objectFit="cover"
  //     objectPosition="50% 50%"
  //     alt={title}
  //     className="featured-image"
  //   />
  // }

  // if (!!url && typeof url === 'string') {
  //   return <img
  //     style={imageStyle}
  //     src={url}
  //     objectFit="cover"
  //     objectPosition="50% 50%"
  //     alt={title} />
  // }

  // return null;
};

export default WorkFeaturedImage;

/* preview
{
    "url": "blob:http://localhost:8000/8f047002-7412-49b9-b441-f30a3c541658",
    "path": "static/assets/mike-dorner-sf_1ZDA1YFw-unsplash.jpg"
}
*/

/* non preview
{
    "childImageSharp": {
        "fluid": {
            "base64": "data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAMABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAIDBf/EABYBAQEBAAAAAAAAAAAAAAAAAAECA//aAAwDAQACEAMQAAAB042WdmAX/8QAGRABAAIDAAAAAAAAAAAAAAAAAQAQAgMS/9oACAEBAAEFAo7DujEK/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGRAAAgMBAAAAAAAAAAAAAAAAARAAAiFR/9oACAEBAAY/AoK9eAL/xAAZEAEAAwEBAAAAAAAAAAAAAAABEBFRAEH/2gAIAQEAAT8h4N8XHkpWJ0I//9oADAMBAAIAAwAAABDvz//EABURAQEAAAAAAAAAAAAAAAAAAAEQ/9oACAEDAQE/EGf/xAAXEQADAQAAAAAAAAAAAAAAAAAQESFh/9oACAECAQE/EK8H/8QAGRABAQADAQAAAAAAAAAAAAAAAREAEEGh/9oACAEBAAE/EFAqzBxYnVcvukEiUyvj4B1//9k=",
            "aspectRatio": 1.6203703703703705,
            "src": "/static/905b9e3012fc63a0eef11c8a5570748c/7366b/mike-dorner-sf_1ZDA1YFw-unsplash.jpg",
            "srcSet": "/static/905b9e3012fc63a0eef11c8a5570748c/5a0b2/mike-dorner-sf_1ZDA1YFw-unsplash.jpg 350w,\n/static/905b9e3012fc63a0eef11c8a5570748c/21302/mike-dorner-sf_1ZDA1YFw-unsplash.jpg 700w,\n/static/905b9e3012fc63a0eef11c8a5570748c/0da5e/mike-dorner-sf_1ZDA1YFw-unsplash.jpg 1050w,\n/static/905b9e3012fc63a0eef11c8a5570748c/801b3/mike-dorner-sf_1ZDA1YFw-unsplash.jpg 1400w,\n/static/905b9e3012fc63a0eef11c8a5570748c/7366b/mike-dorner-sf_1ZDA1YFw-unsplash.jpg 1980w,\n/static/905b9e3012fc63a0eef11c8a5570748c/d5660/mike-dorner-sf_1ZDA1YFw-unsplash.jpg 5827w",
            "sizes": "(max-width: 1980px) 100vw, 1980px",
            "maxHeight": 1224,
            "maxWidth": 1980
        },
        "sizes": {
            "src": "/static/905b9e3012fc63a0eef11c8a5570748c/14b42/mike-dorner-sf_1ZDA1YFw-unsplash.jpg"
        }
    }
}
*/
