import React from "react";
import Content from "./content";

const WorkContent = ({
  content,
  contentComponent,
  handleMarkdownImageClick,
}) => {
  const ContentComponent = contentComponent || Content;

  if (!content) {
    return null;
  }

  return (
    <div
      className="work-content blog-post-content"
      role="presentation"
      onClick={
        !!handleMarkdownImageClick
          ? event => handleMarkdownImageClick(event)
          : undefined
      }
    >
      <ContentComponent className="work-content-body" content={content} />
    </div>
  );
};

export default WorkContent;
