import React from "react";
import WorkFeaturedImage from "./work-featured-image";
import WorkGallery from "./work-gallery";
import WorkContent from "./work-content";

const WorkItemPageTemplate = ({
  title,
  date,
  featuredImage,
  gallery,
  content,
  contentComponent,
  handleFeaturedImageClick,
  handleGalleryImageClick,
  handleMarkdownImageClick,
}) => {
  return (
    <article className="work-post">
      <header className="featured-banner">
        {featuredImage && (
          <WorkFeaturedImage
            imageInfo={featuredImage}
            title={title + " - Featured image"}
            handleImageClick={event => handleFeaturedImageClick(event)}
          />
        )}
        <section className="article-header">
          <h1>{title}</h1>
          <time>{date}</time>
        </section>
      </header>
      <div className="work-container">
        <WorkContent
          content={content}
          contentComponent={contentComponent}
          handleMarkdownImageClick={event => handleMarkdownImageClick(event)}
        />
        <WorkGallery
          images={gallery.images}
          position={gallery.position}
          type={gallery.style}
          handleImageClick={event => handleGalleryImageClick(event)}
        />
      </div>
    </article>
  );
};

export default WorkItemPageTemplate;
